import { getRequestHeaders } from '../../setupAjaxHeaders';

/**
 * Fetches the specified artifact, returning a Promise that resolves with
 * the raw content converted to text of the artifact if the fetch is
 * successful, and rejects otherwise
 */
export function getArtifactContent(artifactLocation) {
  return new Promise((resolve, reject) => {
    var getArtifactRequest = null;
    const url = new URL(artifactLocation);
    if (url.hostname.toLowerCase().endsWith("s3.amazonaws.com")) {
      console.log("getArtifactContent: s3 URL " + artifactLocation);
      getArtifactRequest = new Request(artifactLocation, {
        method: 'GET',
        redirect: 'follow',
      });
    } else {
      console.log("getArtifactContent: non s3 URL " + artifactLocation);
      getArtifactRequest = new Request(artifactLocation, {
        method: 'GET',
        redirect: 'follow',
        headers: new Headers(getRequestHeaders(document.cookie)),
      });
    }
    fetch(getArtifactRequest)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        const fileReader = new FileReader();
        fileReader.onload = (event) => {
          // Resolve promise with artifact contents
          resolve(event.target.result);
        };
        fileReader.readAsText(blob);
      })
      .catch((error) => reject(error));
  });
}

/**
 * Fetches the specified artifact, returning a Promise that resolves with
 * the raw content in bytes of the artifact if the fetch is successful, and rejects otherwise
 */
export function getArtifactBytesContent(artifactLocation) {
  return new Promise((resolve, reject) => {
    var getArtifactRequest = null;
    const url = new URL(artifactLocation);
    if (url.hostname.toLowerCase().endsWith("s3.amazonaws.com")) {
      console.log("getArtifactBytesContent: s3 URL " + artifactLocation);
      getArtifactRequest = new Request(artifactLocation, {
        method: 'GET',
        redirect: 'follow',
      });
    } else {
      console.log("getArtifactBytesContent: non s3 URL " + artifactLocation);
      getArtifactRequest = new Request(artifactLocation, {
        method: 'GET',
        redirect: 'follow',
        headers: new Headers(getRequestHeaders(document.cookie)),
      });
    }
    fetch(getArtifactRequest)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        const fileReader = new FileReader();
        fileReader.onload = (event) => {
          // Resolve promise with artifact contents
          resolve(event.target.result);
        };
        fileReader.readAsArrayBuffer(blob);
      })
      .catch((error) => reject(error));
  });
}
