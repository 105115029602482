import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'

export function User({ username, handleLogout }) {
  return (
    <div>
      <div className="user-card">
        <div className="userbadge">
          <p className="firstLetter">{username.charAt(0).toUpperCase()}</p>
        </div>
        <div>
          <p className="username"> {username} </p>
        </div>
      </div>
      <div className="logout_div">
        <Button className="logoutbtn" onClick={handleLogout}>Logout </Button>
      </div>
    </div>
  )
}

User.propTypes = {
  handleLogout: PropTypes.func.isRequired,
  username: PropTypes.string,
}

export default User
