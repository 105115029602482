import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button } from 'antd/lib/index';
import { ModelPermissionTable } from './ModelPermissionTable';
import { Spacer } from '../../shared/building_blocks/Spacer';
import { FormattedMessage, injectIntl } from 'react-intl';
import {  Select } from 'antd';
const { Option } = Select;

export class AddModelPermissionModelImpl extends React.Component {
  static propTypes = {
    permission: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    handleAddPermission: PropTypes.func.isRequired,
    handleSaveEditPermission: PropTypes.func.isRequired,
    handleDeletePermission: PropTypes.func.isRequired,
    isRequestPending: PropTypes.bool.isRequired,
    intl: PropTypes.any,
  };

  tableColumns = [
    {
      title: this.props.intl.formatMessage({
        defaultMessage: 'Type (user/group)',
        description: 'Column title for type column in editable roles in permission table',
      }),
      dataIndex: 'principal_type',
      width: 185,
    },
    {
      title: this.props.intl.formatMessage({
        defaultMessage: 'Name',
        description: 'Column title for name column in editable roles in permission table',
      }),
      dataIndex: 'principal_name',
      width: 200,
    },
    {
      title: this.props.intl.formatMessage({
        defaultMessage: 'Role',
        description: 'Column title for value column in editable roles in permission table',
      }),
      dataIndex: 'role',
      width: 170,
      editable: true,
    },
  ];

  render() {
    const { form, isRequestPending, handleSaveEditPermission, handleDeletePermission, handleAddPermission, permission } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Spacer direction='vertical' >
        <ModelPermissionTable
          columns={this.tableColumns}
          data={permission}
          onSaveEdit={handleSaveEditPermission}
          onDelete={handleDeletePermission}
        />
        <div style={styles.addTagForm.wrapper}>
          <Form layout='inline' onSubmit={handleAddPermission}>
          <Form.Item>
              {getFieldDecorator('type', {
                initialValue: "user",
                rules: [],
              })(
                <Select
                className='user-Type-select'
                size='large'
                data-test-id='user-Type-select-dropdown'
                style={styles.addTagForm.valueInput}
              >
                <Option value="user" key="user"> User </Option>
                <Option value="group" key="group"> Group </Option>
              </Select>
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('user', {
                rules: [
                  {
                    required: true,
                    message: this.props.intl.formatMessage({
                      defaultMessage: 'Name is required.',
                      description:
                        'Error message for name requirement in editable role in permission table',
                    }),
                  },
                ],
              })(
                <Input
                  aria-label='user name'
                  placeholder={this.props.intl.formatMessage({
                    defaultMessage: 'Name',
                    description:
                      'Default text for User placeholder in editable role in permission table',
                  })}
                  style={styles.addTagForm.nameInput}
                />,
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('Permission', {
                initialValue: "reader",
                rules: [],
              })(
                <Select
                className='permission-Type-select'
                size='large'
                data-test-id='permission-Type-select-dropdown'
                style={styles.addTagForm.valueInput}
              >
                <Option value="reader" key="reader"> Reader </Option>
                <Option value="editor" key="editor"> Editor </Option>
                <Option value="manager" key="manager"> Manager </Option>
              </Select>
              )}
            </Form.Item>
            <Form.Item>
              <Button loading={isRequestPending} htmlType='submit' data-test-id='add-tag-button'>
                <FormattedMessage
                  defaultMessage='Add'
                  description='Add button text in editable role in permission table'
                />
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Spacer>
    );
  }
}

const styles = {
  addTagForm: {
    wrapper: { marginLeft: 7 },
    label: {
      marginTop: 20,
    },
    nameInput: { width: 170, height: 38},
    valueInput: { width: 170 },
  },
};

export const AddModelPermissionModel = injectIntl(Form.create()(AddModelPermissionModelImpl));
